<template>
  <section class="Brand pt-2">
    
    <div class="page-header">
      <h3 class="page-title">Brands</h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link class="btn btn btn-add btn-primary" :to="`/catalog/add-brand`">Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Brand Name</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="brands">
                <tr v-for="(brand, index) in brands" :key="brand.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ brand.name }}</td>
                  <td>
                    <div v-if="brand.images">
                      <img :src="`${imageApiURL}/${brand.images.thumb_s}`" class="show-img img-fluid" />
                    </div>
                    <div v-if="!brand.images">No Image</div>
                  </td>
                  <td>
                    <router-link class="mr-2 table-icon" v-b-tooltip.hover title="Edit"
                      :to="`/catalog/edit-brand/${brand.id}`">
                      <i class="mdi mdi-pencil"></i>
                    </router-link>
                    <!-- <a v-on:click="deleteBrand(brand.id)" class="table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-12 p-3 text-center" v-if="brands == ''">No data found!</div>
            <div style="border-top: 1px solid #e9ecef" v-if="brands">
              <paginate :page-count="totalPage" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'" :page-class="'page-item'">
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { catalogApi } from "../../../api";

const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import Paginate from 'vuejs-paginate'
export default {
  name: "view_brand",
  components: {
     Paginate
  },
  data() {
    return {
      brands: "",
      
      totalPage: this.totalPage,
      imageApiURL,
    };
  },
  mounted() {
    let page = 1;
    this.getBrands(page);
  },
  methods: {
    async getBrands(page) {
      // 
      const { data } = await catalogApi.getBrands(page);
      
      console.log("fffdataccc");
      if (data) {
        console.log("fffdata");
        this.brands = data.data.data.data;
        this.totalPage = data.data.data.last_page;
        this.current_page = data.data.data.current_page;
      } else {
        console.log("fff");
        this.$swal({
          toast: true,
          position: "bottom-end",
          title: "Network Error",
          text: "",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Reload",
        }).then((result) => {
          if (result.value) {
            
            this.getBrands(page);
          }

        });
      }

    },
    clickCallback: function (page) {
      this.getBrands(page)
    },
    deleteBrand(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          
          const response = catalogApi.deleteBrand(id).then((response) => {
            
            if (response.data.code == 200) {
              this.getBrands(1);
              if (result.value) {
                this.$swal("Deleted!", "Your file has been deleted.", "success");
              }
            }
          });
        }
      });
    },
  },
};
</script>